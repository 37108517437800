import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
    dsn: "https://dcbe324180a570a6f3a39751fbd8bb54@o467353.ingest.us.sentry.io/4508043234836480",
    tracesSampleRate: 1,

    integrations: [
        Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
        }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    ignoreErrors: [
        "Hydration",
        "hydration",
        "Hydrating",
        "hydrating",
        "https://reactjs.org/docs/error-decoder.html?invariant=422",
        "https://reactjs.org/docs/error-decoder.html?invariant=423",
        "https://reactjs.org/docs/error-decoder.html?invariant=425",
    ],
});

startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <RemixBrowser />
        </StrictMode>,
    );
});
